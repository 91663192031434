// This component has been migrated to V3
// If you make changes to this component, please drop a note in #lp-instui-react-upgrade

import lodashSet from 'lodash.set';
import * as Yup from 'yup';

export function yupToFormErrors<TForm extends object = any>(yupErrors: unknown): FormErrors<TForm> {
  if (yupErrors instanceof Yup.ValidationError) {
    const yupErrorsValues: Array<Yup.ValidationError> = Object.values(yupErrors.inner);

    // reduceRight() ends up showing the first Yup error for each key instead of the last
    return yupErrorsValues.reduceRight((acc, err) => {
      if (err.path) {
        lodashSet(acc, err.path, err.message);
      }
      return acc;
    }, {} as FormErrors<TForm>);
  }
  return {} as FormErrors<TForm>;
}

/** Shorthand for `try { schema.validateSync(form); return {}; } catch (err) { return yupToFormErrors(err) } */
export function validateWithYup<TSchema extends object>(
  schema: Yup.ObjectSchema<TSchema>,
  form: unknown,
): FormErrors<TSchema> {
  try {
    schema.validateSync(form, { abortEarly: false });
  } catch (err) {
    return yupToFormErrors(err);
  }
  return {} as FormErrors<TSchema>;
}

/**
 * Sometimes, we want to validate a `Yup.array()`, but we're more interested
 * in validating each object than treating the array as a list. In cases where
 * accessing errors with an object's ID is easier than accessing errors with the
 * index of the array, post-process the errors with this.
 *
 * @example
 * const errors = validateWithYup(schema, form);
 * return {
 *   ...errors,
 *   actions: indexErrorsById(errors.actions, form.actions)
 * };
 */
export function indexErrorsById<FE extends FormErrorsInner<object>, TItem extends MaybeHasId>(
  formErrors: Array<FE> | string | undefined,
  originalArray: Array<TItem>,
) {
  if (
    !formErrors ||
    // swallow errors for the array as a whole, like "List must have at least 1 item"
    typeof formErrors === 'string'
  ) {
    return undefined;
  }

  return formErrors.reduce(
    (acc, error, index) => {
      const id = originalArray[index]?.id;
      if (error != null && id != null) {
        acc[id] = error;
      }
      return acc;
    },
    {} as Record<NonNullable<TItem['id']>, FE | undefined>,
  );
}

/**
 * FormErrors represents the type of an `errors` object returned by `yupToFormErrors`
 * and `validateWithYup`.
 * Based on the shape of an input object, each field of each nested object may have
 * a `string` error message, or objects/arrays themselves may be a string error message.
 */
export type FormErrors<TForm = any> = 0 extends 1 & TForm // only `any` makes this true
  ? any
  : TForm extends Array<infer TItem> // if the field is an array, support array of errors
  ? Array<FormErrorsInner<TItem>>
  : TForm extends object
  ? {
      [K in keyof TForm]?: FormErrorsInner<TForm[K]>;
    }
  : string | undefined; // if the field is not an object, support string error

/**
 * `FormErrors` will never be a string at the top level, but this type can be a string. Used for
 * nested objects and arrays. This makes validation more convenient, and only assumes that you
 * always pass *something* to the validation function.
 */
type FormErrorsInner<TForm = any> = 0 extends 1 & TForm // only `any` makes this true
  ? any
  : TForm extends Array<infer TItem> // if the field is an array, support string or array of errors
  ? string | Array<FormErrors<TItem>>
  : TForm extends object // if the field is an object, support string or object of errors
  ?
      | string
      | {
          [K in keyof TForm]?: FormErrors<TForm[K]>;
        }
  : string | undefined; // if the field is not an object, support string error

interface MaybeHasId {
  id?: string | number;
}
