import { chain, prop, flatten } from 'ramda';

import { snakeToRegularCase } from 'utils/strings';
import { Group, Service, Placement } from './types';

export const findServices = (services: Group[], serviceIds: number[]): Service[] => {
  const allServices = chain(prop('services'), services); // flattens the array of services
  return allServices.filter(service => serviceIds.includes(service.id));
};

export const findService = (services: Group[], serviceName: string): Service | undefined => {
  const allServices = chain(prop('services'), services);
  return allServices.find(service => service.name === serviceName);
};

export const filterServices = (services: Group[], filterIds: number[]): Group[] => {
  return services.map(group => ({
    ...group,
    services: group.services.filter(service => !filterIds.includes(service.id)),
  }));
};

export const findPlacements = (placements: Placement[], placementIds: number[]) => {
  return placements.filter(placement => placementIds.includes(placement.id));
};

export const findPlacementFields = (obj: any): any => {
  if (obj && typeof obj === 'object') {
    // Check if obj is an object
    if (obj.placements) return obj; // If it has a 'placements' field, return it
    // Recursively call findPlacement on all the values of the object, flatten them, and find the first truthy value
    return flatten(Object.values(obj).map(findPlacementFields)).find(Boolean);
  }
  return undefined; // If obj is not an object or there's no 'placements' field, return undefined
};

export const matchTagsToPlacements = (
  config: any,
  relatedData: { ltiPlacements: { name: string; id: number }[] },
): number[] => {
  const tags = findPlacementFields(config)?.placements;

  return tags
    ? (tags
        .map(tag => {
          const tagName = snakeToRegularCase(tag.placement);
          const id = relatedData?.ltiPlacements.find(placement => placement.name === tagName)?.id;
          return id;
        })
        .filter(tag => tag !== undefined) as number[])
    : [];
};
